export const initialData = [
  {
    id: 1,
    tittle: "Expertise & Experience",
    paragraph:
      "With decades of experience in the construction industry, Sodmar has built a reputation for delivering high-quality projects. Our team of seasoned professionals brings unparalleled expertise to every project, ensuring excellence from start to finish.",
  },
  {
    id: 2,
    tittle: "Tailored Solutions",
    paragraph:
      "We understand that every project is unique. That's why we offer customized construction solutions that cater to your specific needs, preferences, and budget. Whether it's a residential, commercial, or industrial project, we design and build with your vision in mind.",
  },
  {
    id: 3,
    tittle: "Commitment to Quality",
    paragraph:
      "Quality is at the heart of everything we do. From sourcing the finest materials to employing the best construction practices, we ensure that every project meets the highest standards of craftsmanship and durability.",
  },
  {
    id: 4,
    tittle: "Timely Delivery",
    paragraph:
      "At Sodmar, we value your time. We pride ourselves on completing projects on schedule without compromising on quality. Our efficient project management ensures that deadlines are met, so you can start enjoying your new space as planned.",
  },
  {
    id: 5,
    tittle: "Transparent Communication",
    paragraph:
      "We believe in building trust through clear and honest communication. From the initial consultation to project completion, we keep you informed every step of the way, ensuring a seamless and stress-free experience.",
  },
  {
    id: 6,
    tittle: "Sustainability and Innovation",
    paragraph:
      "We are committed to sustainable building practices that minimize environmental impact. By integrating innovative technologies and eco-friendly solutions, we create spaces that are not only beautiful but also responsible and future-ready.",
  },
  {
    id: 7,
    tittle: "Comprehensive Services",
    paragraph:
      "From initial design and planning to construction and final handover, we offer a full range of services under one roof. This comprehensive approach ensures consistency, efficiency, and peace of mind throughout your project.",
  },
  {
    id: 8,
    tittle: "Client Satisfaction",
    paragraph:
      "Your satisfaction is our ultimate goal. We take pride in our long list of happy clients who trust us for our dedication, reliability, and exceptional results. When you choose Sodmar, you're choosing a partner who is committed to your success.",
  },
  {
    id: 9,
    tittle: "Safety First",
    paragraph:
      "Safety is non-negotiable at Sodmar. We adhere to the strictest safety protocols to ensure the well-being of our workers, clients, and the community. Every project is executed with the highest regard for safety standards.",
  },
  {
    id: 10,
    tittle: "Strong Industry Relationship",
    paragraph:
      "Our strong relationships with suppliers, subcontractors, and industry partners allow us to deliver superior quality at competitive prices. These partnerships enable us to source the best materials and skilled labor for your project.",
  },
];
