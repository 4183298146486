import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { ButtonStyled } from "../../component/header/styled";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateProjectMutation } from "../../redux/features/project/projectApiSlice";
import { uploadImg } from "../services/addProjectService";
import { CircularProgress } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 600,
  borderRadius: "10px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [createProject, { isLoading }] = useCreateProjectMutation();

  const navigate = useNavigate();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setImage(file);
  };
  const handleProjectNameChange = (e) => {
    setName(e.target.value);
  };

  const handleProjectLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleProjectDescChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      toast.error("Please upload an image.");
      return;
    }
    try {
      const data = {
        description,
        name,
        location,
      };

      const response = await createProject(data).unwrap();

      const uploadResponse = await uploadImg(image, response.project._id);

      if (response && uploadResponse && !isLoading) {
        toast.success("Project created successfully");
        setLocation("");
        setName("");
        setDescription("");
        handleClose();
        navigate("/admin/project");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error uploading the file or sending data");
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{ color: "#fff", fontFamily: "Inter" }}
      >
        Add Project
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalForm>
            <form encType="multipart/form-data">
              <div>
                <label>Project Name</label>
                <input
                  type="text"
                  name="projectName"
                  placeholder="project name"
                  required
                  value={name}
                  onChange={handleProjectNameChange}
                />
              </div>
              <div>
                <label>Project Image</label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div>
                <label>Project Location</label>
                <input
                  type="text"
                  name="location"
                  placeholder="Project Location"
                  required
                  value={location}
                  onChange={handleProjectLocationChange}
                />
              </div>
              <div>
                <label>Project Description</label>
                <input
                  type="text"
                  name="desc"
                  placeholder="Project Description"
                  required
                  value={description}
                  onChange={handleProjectDescChange}
                />
              </div>
              {isLoading ? (
                <ButtonStyled
                  type="submit"
                  style={{ textAlign: "center" }}
                  onClick={handleSubmit}
                >
                  <CircularProgress style={{ color: "#fff" }} />
                </ButtonStyled>
              ) : (
                <ButtonStyled
                  type="submit"
                  style={{ textAlign: "center" }}
                  onClick={handleSubmit}
                >
                  Create
                </ButtonStyled>
              )}
            </form>
          </ModalForm>
        </Box>
      </Modal>
    </div>
  );
}

const ModalForm = styled.form`
  form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    label {
      display: block;
      font-size: 2rem;
      font-weight: 500;
      /* padding: 0.5; */
      color: rgba(102, 102, 102, 0.8);
    }
    input[type="text"],
    input[type="file"],
    textarea,
    input[type="password"] {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 10px;
      outline: none;
    }
  }
`;
