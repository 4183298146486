import React, { useState } from "react";
import { useGetAllServicesQuery } from "../../redux/features/services/serviceApiSlice";
import { styled } from "styled-components";
import { useCreateAppointmentMutation } from "../../redux/features/appointments/appointmentApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  validateDate,
  validateEmail,
  validatePhone,
  validateService,
  validateName,
} from "../../service/Appointment";

const Appointment = () => {
  const [name, setName] = useState("");
  const [service, setService] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");

  const { data, isLoading } = useGetAllServicesQuery();
  const [createAppointment, { isLoading: isLoadingAppointment }] =
    useCreateAppointmentMutation();

  const navigate = useNavigate();

  const handleSubmitAppointment = async (e) => {
    e.preventDefault();
    let validationFailed = false;

    if (!validateEmail(email)) {
      toast.error("email is compulsory");
      validationFailed = true;
    }
    if (!validatePhone(phone)) {
      toast.error("phone is compulsory");
      validationFailed = true;
    }

    if (!validateName(name)) {
      toast.error("name is mandatory");
      validationFailed = true;
    }

    if (!validateService(service)) {
      toast.error("service is mandatory");
      validationFailed = true;
    }

    if (!validateDate(date)) {
      toast.error("date is mandatory");
      validationFailed = true;
    }

    if (validationFailed) {
      return;
    }

    try {
      const data = {
        name,
        service,
        date,
        email,
        phone,
        notes,
      };

      const response = await createAppointment(data).unwrap;
      if (response) {
        toast.success("appointment booked successfully");
        navigate("/");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#faf8ed",
      }}
    >
      <Text
        style={{
          marginTop: "15rem",
        }}
        className="text-[32px] font-[700]"
      >
        Welcome Back
      </Text>
      <TextHighlight>
        Book an appointment with us, fill in the appointment details below.
      </TextHighlight>
      <FormContainer>
        <form>
          <FormBox>
            <HeaderTextWrapper>
              <HeaderText>Select Service</HeaderText>
            </HeaderTextWrapper>
            <NameGrid>
              {!isLoading &&
                data &&
                data?.services?.map((bookingData) => {
                  const { name } = bookingData;
                  return (
                    <>
                      <NameFlex>
                        <input
                          type="radio"
                          value={name}
                          onChange={(e) => setService(e.target.value)}
                        />
                        <label htmlFor={name}>{name}</label>
                      </NameFlex>
                    </>
                  );
                })}
            </NameGrid>
          </FormBox>
          <FormBoxA>
            <DateInputWrapper>
              <HeaderText>
                <label htmlFor="date">Select Appointment Date:</label>
              </HeaderText>
              <input
                type="date"
                placeholder="enter your convenient date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </DateInputWrapper>
          </FormBoxA>
          <FormBoxA>
            <DateInputWrapper>
              <HeaderText>
                <label htmlFor="name">Appointor's Name:</label>
              </HeaderText>
              <input
                type="text"
                placeholder="enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </DateInputWrapper>
          </FormBoxA>
          <FormBoxA>
            <DateInputWrapper>
              <HeaderText>
                <label htmlFor="email">Appointor's Email:</label>
              </HeaderText>
              <input
                type="email"
                placeholder="enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DateInputWrapper>
          </FormBoxA>
          <FormBoxA>
            <DateInputWrapper>
              <HeaderText>
                <label htmlFor="ohone">Appointor's Phone:</label>
              </HeaderText>
              <input
                type="tel"
                placeholder="enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </DateInputWrapper>
          </FormBoxA>
          <FormBoxA>
            <DateInputWrapper>
              <HeaderText>
                <label htmlFor="notes">Appointment Note:</label>
              </HeaderText>
              <textarea
                type="text"
                placeholder="any note for the appointment"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </DateInputWrapper>
          </FormBoxA>
          {isLoadingAppointment ? (
            <SubmitBtn type="submit" onClick={handleSubmitAppointment}>
              <CircularProgress style={{ color: "#fff" }} />
            </SubmitBtn>
          ) : (
            <SubmitBtn type="submit" onClick={handleSubmitAppointment}>
              Submit
            </SubmitBtn>
          )}
        </form>
      </FormContainer>
    </div>
  );
};

export default Appointment;

const SubmitBtn = styled.button`
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  cursor: pointer;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #de8f5f;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    opacity: 0.25;
    background: #de8f5f;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #e4e4d0;
    color: #000;
  }
  @media only screen and (max-width: 1000px) {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
  }
  @media only screen and (max-width: 430px) {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
  }
`;

const DateInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 55px;

  input {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
  }

  textarea {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding-left: 4rem;
    gap: 0;
    input {
      width: 200px;
    }
    textArea {
      width: 200px;
    }
    label {
      font-size: 14px;
      font-weight: 400;

      line-height: 20px;
    }
  }
`;

const HeaderTextWrapper = styled.div`
  position: relative;
  margin-left: -320px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    background: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NameFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    width: 150px;
  }
`;

const NameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    padding: 2rem 4rem 2rem 4rem;
  }
`;
const Text = styled.h1`
  text-align: center;
  font-weight: 600;
  margin-top: 100px;
`;

const HeaderText = styled.p`
  font-size: 20px;
  font-weight: 500;
  /* color: #ccc; */
`;
const TextHighlight = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 200px;
    font-weight: 200;
    margin-left: 100px;
  }
`;

const FormBox = styled.div`
  width: 100%;
  background-color: #faf8ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px 45px 0;
  gap: 0;
  margin-top: 15px;

  @media only screen and (max-width: 768px) {
    padding-left: 4rem;
  }
`;
const FormBoxA = styled.div`
  width: 100%;
  background-color: #faf8ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px 45px 0;
  gap: 0;
  margin-top: 15px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`;
