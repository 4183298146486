import { useNavigate } from "react-router-dom";
import { Button } from "../../pages/home/Home";
import { BuildingStyled } from "../../pages/home/styled";

const Text = () => {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/register");
  };
  return (
    <>
      <BuildingStyled className="w-full md:w-[600px]">
        <h2 className="w-full text-[28px] font-[700]">Start building with</h2>
        <h3 className="w-full text-[24px]">
          SodMar Construction and <br />
          Engineering Concept Limited
        </h3>
        <p className="text-[14px] w-full">
          Welcome to Sodmar Construction Company, where we provide high-quality
          building solutions that are tailored to meet your specific needs. At
          Sodmar, we take pride in our commitment to delivering exceptional,
          high-quality construction services that are perfectly tailored to meet
          your specific needs and vision. Our team of skilled professionals,
          engineers, architects, and craftsmen are dedicated to turning your
          dreams into reality, whether you're envisioning a new home, a
          commercial space, or a renovation project. We understand that every
          project is unique, and we approach each one with a personalized touch.
          Our mission is to exceed your expectations, and we achieve this by
          focusing on quality, precision, and attention to detail in every
          aspect of our work.
        </p>
        <Button type="submit" onClick={handleSignUp}>
          Get Started
        </Button>
      </BuildingStyled>
    </>
  );
};

export default Text;
