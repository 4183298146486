import { ImageStyled } from "../../pages/home/styled";
import buildImg2 from "../../assets/bld2.jpg";
import buildImg3 from "../../assets/bld3.jpg";
import img1 from "../../assets/builder1.svg";
import img2 from "../../assets/builder2.svg";

const Images = () => {
  return (
    <ImageStyled>
      <div className="top">
        <div className="top_a">
          <img src={img1} alt="build" />
        </div>
        <div className="top_b">
          <img src={buildImg2} alt="" />
        </div>
      </div>
      <div className="top">
        <div className="bottom_a">
          <img src={buildImg3} alt="build" />
        </div>
        <div className="bottom_b">
          <img src={img2} alt="" />
        </div>
      </div>
    </ImageStyled>
  );
};

export default Images;
