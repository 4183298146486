import React from "react";
import styled from "styled-components";
import { Container } from "../feedbacks/Feedbacks";
import ApprovalIcon from "@mui/icons-material/Approval";

export const StoryHeading = styled.p`
  color: var(--midnight-blue, #141e61);
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.2px; /* 81.667% */
  letter-spacing: 2.8px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const serviceData = [
  {
    id: 1,
    title: "Building Approval",
    subtitle:
      "Building approval is the process of ensuring that construction or renovation projects comply with local regulations and standards. This involves reviewing plans, issuing permits, and conducting inspections to guarantee safety and adherence to zoning laws. The objective is to confirm that the project meets all legal requirements, ensuring it’s safe and fit for use.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 2,
    title: "Real Estate Agency",
    subtitle:
      "Real estate agency is carried  out by a Firm, company or organization that facilitates the buying, selling, or renting of properties on behalf of clients. They typically employ licensed real estate agents who act as intermediaries between buyers and sellers, helping to negotiate transactions ensure that the buyer is not buying above the market price and the seller is not selling below the market value and also to ensure legal compliance.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 3,
    title: "Mechanical Design",
    subtitle:
      "Mechanical design involves the creation and development of mechanical systems and components. This process includes conceptualizing, drafting, and analyzing designs to ensure they function efficiently and safely. It also involves selecting materials, considering manufacturing methods, and optimizing performance. The goal is to produce reliable, cost-effective designs that meet specific requirements and industry standards.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },

  {
    id: 4,
    title: "Structural Design",
    subtitle:
      "Structural design involves the planning and development of frameworks and load-bearing elements in buildings, bridges, and other structures. This process includes analyzing forces, selecting materials, and creating detailed plans to ensure stability, safety, and durability. The goal is to design structures that can withstand various loads and environmental conditions while maintaining functionality and aesthetic appeal.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 5,
    title: "Facility Management",
    subtitle:
      "Facility management involves overseeing and maintaining the physical assets of an organization to ensure they operate effectively and efficiently. This includes managing buildings, equipment, utilities, and services to support the organization's core business functions. Facility managers handle tasks such as maintenance, security, cleaning, space planning, and ensuring compliance with regulations to create safe, productive, and comfortable environments for employees and visitors.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 6,
    title: "Project Supervision",
    subtitle:
      "Project supervision involves overseeing the execution of a project to ensure it is completed on time, within budget, and according to specifications. This includes coordinating teams, monitoring progress, ensuring quality control, and addressing any issues that arise during the construction or development process. The goal is to ensure the project meets all objectives and standards while minimizing risks and delays.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 7,
    title: "Land Surveying",
    subtitle:
      "Land surveying involves the precise measurement and mapping of land to determine boundaries, elevations, and features of a property. This process includes collecting data using specialized equipment and techniques to create accurate maps and legal descriptions. The goal is to provide essential information for land development, construction, and legal purposes, ensuring that all land-related projects are based on accurate and reliable data.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
  {
    id: 6,
    title: "Architectural Drawing",
    subtitle:
      "Architectural drawing is the process of creating detailed plans and blueprints that visually represent the design and layout of buildings and structures. This includes floor plans, elevations, sections, and detailed specifications that guide construction. The goal is to translate design concepts into clear, precise drawings that ensure accurate construction, functionality, and aesthetic appeal, while also meeting building codes and regulations.",
    icon: <ApprovalIcon style={{ fontSize: "25px" }} />,
  },
];

const Services = () => {
  const shortenDescription = (description) => {
    return description.length > 250
      ? description.substring(0, 250) + "..."
      : description;
  };

  return (
    <Container>
      <h1>
        {" "}
        <StoryHeading style={{ marginTop: "2rem" }}>
          Our <span style={{ color: " #df950e" }}>Services</span>
        </StoryHeading>
      </h1>

      <div>
        <BlogHome>
          {serviceData?.map((card, index) => {
            const { title, subtitle, icon } = card;

            return (
              <>
                <Card key={index}>
                  <InnerCard>
                    <div className="flex flex-col gap-[1rem]">
                      <p style={{ marginTop: "1rem" }}>{icon}</p>
                      <h1
                        style={{ fontFamily: "Montserrat", color: " #df950e" }}
                        className="text-[18px] font-[700]"
                      >
                        {title}
                      </h1>
                      <p
                        style={{
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                        }}
                        className="text-[#15265E] font-[500]"
                      >
                        {shortenDescription(subtitle)}
                      </p>
                    </div>
                  </InnerCard>
                </Card>
              </>
            );
          })}
        </BlogHome>
      </div>
    </Container>
  );
};

export default Services;

const Button = styled.button`
  background-color: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Montserrat;
  text-align: right;
  margin-bottom: 1rem;
  padding: 10px 15px;
  cursor: pointer;
`;

const BlogHomeWrapper = styled.div`
  font-family: Inter;
  padding-top: 12rem;
  color: #de8f5f;
  // background-color: #faf8ed;
  text-align: center;
`;
const BlogHome = styled.div`
  // max-width: 800px;
  // width: 100%;
  // padding: 5rem 12rem 5rem 12rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: center;
  gap: 3rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

const Card = styled.div`
  margin: 3rem 0;
  max-width: 800px;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50% 20px 50% 20px;
  padding: 2rem;
  // background-color: #faf8ed;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  img {
    max-width: 100%;
    width: 100%;
    height: 300px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 80px 0px rgba(0, 0, 0, 0.2);
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem;
  font-family: Inter;
  text-align: left;
  line-height: 20px;
  color: black;
`;
