import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import TokenService from "../../service/TokenService";
import { logOut, setCredentials } from "../features/auth/authSlice";

const apiLink =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_PROD
    : process.env.REACT_APP_BACKEND_DEV;

const baseQuery = fetchBaseQuery({
  baseUrl: apiLink,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.auth?.token;
    const tokenExpired = TokenService.isAccessExpired();
    if (token) {
      if (!tokenExpired) {
        headers.set("authorization", `Bearer ${token.accessToken}`);
      } else {
        headers.set("authorization", `Bearer ${token.accessToken}`);
        headers.set("x-refresh", `${token.refreshToken}`);
      }
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    console.log("sending refresh token");
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    console.log(refreshResult);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      console.log(user);
      // store the new token
      api.dispatch(setCredentials({ response: refreshResult.data }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getProject: builder.query({
      query: (displayData) => ({
        url: "/project/get",
      }),
    }),
  }),
});
