const dev = process.env.REACT_APP_NODE_ENV !== "production";
export const loginErrorHandler = (err) => {
  if (process.env.REACT_APP_NODE_ENV !== "production") console.log(err);

  if (err?.data?.message) return err.data.message;

  if (!err?.originalStatus) {
    // isLoading: true until timeout occurs
    return "No Server Response";
  } else if (err?.originalStatus === 400) {
    return "Missing Username or Password";
  } else if (err?.originalStatus === 401) {
    return "Unauthorized";
  } else {
    return "Login Failed";
  }
};

export const errorHandler = (err) => {
  if (process.env.REACT_APP_NODE_ENV !== "production") console.log(err);

  if (err?.data?.message) return err.data.message;

  if (!err?.originalStatus) {
    // isLoading: true until timeout occurs
    return "No Server Response";
  } else if (err?.originalStatus === 400) {
    return "Missing Username or Password";
  } else if (err?.originalStatus === 401) {
    return "Unauthorized";
  } else {
    return "OpsSomething went wrong!!!";
  }
};

export const signupErrorHandler = (err) => {
  if (process.env.REACT_APP_NODE_ENV !== "production") console.log(err);

  if (err?.data?.message) return err.data.message;

  if (err?.status === 403) {
    return "User with the same email or phone number exists";
  } else if (err?.originalStatus === 401) {
    return "Unauthorized";
  } else if (err?.status === 409) {
    return "User with the same email or phone number exists";
  } else {
    return "OpsSomething went wrong!!!";
  }
};

export const responseErrorHandler = (error) => {
  if (dev) console.log(error);
  if (error?.status === 422) {
    const errorArrays = error?.data?.errors;
    if (errorArrays.length > 0) {
      let errorKeys = errorArrays.map(
        (item) => Object.keys(item)[0] + ": " + Object.values(item)[0]
      );
      let fields = "";
      errorKeys.map(
        (item) => (fields = fields + `${fields === "" ? "" : `\n`}` + item)
      );

      if (dev) console.log("fields", fields);
      return fields;
    }
  }

  let resMessage =
    (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
    error?.response?.data ||
    error?.message ||
    error.toString();

  if (error?.originalStatus === 404)
    resMessage = "The requested resource was not found";
  if (error?.originalStatus === 401) resMessage = "Unauthorize";
  if (error?.originalStatus === 409) resMessage = "Duplicate Entry";
  if (error?.originalStatus === 500)
    resMessage = "Oops Something went wrong Please try again later!!!";
  if (resMessage === "Request failed with status code 500") {
    resMessage = "Oops Something went wrong Please try again later!!!";
  }

  if (resMessage === "Network Error")
    resMessage = "Oops, it seems you do not have internet access!!";
  if (resMessage === "invalid signature")
    resMessage = "Oops Seems the link has expired";

  if (dev) console.log("Error Message: ", resMessage);

  if (typeof resMessage === "object") resMessage = JSON.stringify(resMessage);

  return resMessage;
};
