import React from "react";
import { styled } from "styled-components";
import { useGetUserQuery } from "../../redux/features/auth/authApiSlice";

const BlogAuthor = ({ userId }) => {
  const { data, isLoading, error } = useGetUserQuery(userId);
  return (
    <Wrapper>
      <div style={{ fontSize: "12px", fontWeight: "bold" }}>Written by</div>
      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
        {!isLoading && data ? (
          data.user.lastName
        ) : (
          <span className="text-md font-[400]">Anonymous</span>
        )}
      </div>
      <div style={{ fontSize: "14px", fontWeight: "bold" }}>
        {!isLoading && data && data.user.firstName}
      </div>
    </Wrapper>
  );
};

export default BlogAuthor;

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
