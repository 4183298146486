import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "../../api/apiSlice";

const baseEndPoint =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_PROD
    : process.env.REACT_APP_BACKEND_DEV;

export const authApiSlice = apiSlice.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseEndPoint}` }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: `/user/login`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const userCreateApiSlice = apiSlice.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseEndPoint}` }),
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: (credentials) => ({
        url: `/user/create`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const createAdminApiSlice = apiSlice.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseEndPoint}` }),
  endpoints: (builder) => ({
    createAdminAccount: builder.mutation({
      query: (credentials) => ({
        url: `/user/createadmin`,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});
export const removeUserApiSlice = apiSlice.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseEndPoint}` }),
  endpoints: (builder) => ({
    removeUser: builder.mutation({
      query: ({ id }) => ({
        url: `/user/delete/${id}`,
        method: "DELETE",
        body: { id },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `/user/update/${id}`,
        method: "PUT",
        body: { id, ...credentials },
      }),
    }),
  }),
});

export const getAllUsersApiSlice = apiSlice.injectEndpoints({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseEndPoint}` }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (credentials) => ({
        url: `/user/admin/all-users`,
      }),
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `/user/get/${id}`,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;

export const { useCreateAccountMutation } = userCreateApiSlice;

export const { useCreateAdminAccountMutation } = createAdminApiSlice;

export const { useGetAllUsersQuery, useGetUserQuery } = getAllUsersApiSlice;
export const { useRemoveUserMutation, useUpdateUserMutation } =
  removeUserApiSlice;
