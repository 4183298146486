import React, { useState } from "react";
import { ButtonStyledSignOut } from "./styled";
import { Logo } from "./Header";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CloseIcon from "@mui/icons-material/Close";

const MobileHeader = () => {
  const [nav, setNav] = useState(false);

  const handleToggle = () => {
    setNav(!nav);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [mobileNav, setMobileNav] = useState(false);

  const handleSignOut = () => {
    dispatch(logOut());
    toast.success("Signout successful");
    navigate("/");
  };
  return (
    <MobileStyle>
      <div>
        <MobileLogo>
          <NavLink to={"/"}>
            <Logo />
          </NavLink>
        </MobileLogo>
      </div>

      <div>
        <ul>
          {mobileNav && (
            <NavMobile>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/homeprojectdisplay">Project</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                {user ? (
                  <ButtonStyledSignOut onClick={handleSignOut}>
                    Signout
                  </ButtonStyledSignOut>
                ) : (
                  <NavLink to="/login">Login</NavLink>
                )}
              </li>
            </NavMobile>
          )}
          <IconControlBox onClick={handleToggle}>
            {!mobileNav ? (
              <TableRowsIcon style={{ fontSize: "30px" }} />
            ) : (
              <CloseIcon style={{ fontSize: "30px" }} />
            )}
          </IconControlBox>
        </ul>
      </div>
    </MobileStyle>
  );
};

export default MobileHeader;
const IconControlBox = styled.i`
  size: 50px;
`;
const MobileStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  text-align: center;
  font-family: Inter;

  position: relative;
  /* width: 100%; */
  margin-top: 2px;
  height: 70px;
  flex-shrink: 0;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding-left: 4rem;
  padding-right: 4rem;
  background-color: #faf8ed;
`;

const MobileLogo = styled.div`
  h1 {
    font-size: 20px;
    /* padding-top: 1rem; */
  }
`;

export const NavMobile = styled.ul`
  font-family: Inter;
  /* background-color: #faf8ed; */
  position: fixed;

  display: flex;
  flex-direction: column;
  margin-top: 3px;
  justify-content: start;
  width: 30%;
  gap: 2rem;
  height: 30rem;
  padding: 2rem;
  li {
    display: flex;
    justify-content: start;
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #000;
    font-size: 12px;
  }
`;
