import { React, useRef } from "react";
import { NavLink } from "react-router-dom";

import emailjs from "@emailjs/browser";
import {
  CircleStyles,
  ContactBoxA,
  ContactBoxB,
  ContactPageStyle,
  ContactSectionStyled,
  ContentUp,
  GrayBackground,
  LinkStyle,
} from "./ContactStyles";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineMail } from "react-icons/ai";

const ContactBoxLeft = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xu0k3ks",
        "template_hgpl9rp",
        form.current,
        "D4X16UKWpRYMED4ZY"
      )
      .then(
        (result) => {},
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <h5>Contact Form</h5>
      <h2>Send Us A Message</h2>
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="user_name" required placeholder="Name" />

        <input type="text" name="email" required placeholder="Email" />

        <textarea
          name="message"
          required
          //   value={}
          //   onChange={}
          cols="30"
          rows="10"
        ></textarea>
        <button>Submit</button>
      </form>
    </>
  );
};

const ContactBoxRight = () => {
  return (
    <>
      <h5>Contact info</h5>
      <h2>Contact Us</h2>

      <p>
        <span></span>
      </p>
      <div className="flexD">
        <CircleStyles>
          <AiOutlineMail />
        </CircleStyles>
        <p>
          +2348036013765 <br /> +2348070551910
        </p>
      </div>
      <div className="flexD">
        <CircleStyles>
          <AiOutlineMail />
        </CircleStyles>
        <h6>sodmarconcept@gmail.com</h6>
      </div>

      <div className="flexD">
        <CircleStyles>
          <HiOutlineLocationMarker />
        </CircleStyles>
        <p>
          10, Moloney Bus-Stop, Express Road, <br /> Federal Polytechnic Ilaro,
          Ogun State
        </p>
      </div>
    </>
  );
};

const ContactSection = () => {
  return (
    <ContactSectionStyled>
      <ContactBoxA>
        <ContactBoxLeft />
      </ContactBoxA>
      <ContactBoxB>
        <ContactBoxRight />
      </ContactBoxB>
    </ContactSectionStyled>
  );
};

const MainContentUp = () => {
  return (
    <ContentUp>
      <LinkStyle>
        <NavLink>Home /</NavLink>
        <NavLink>Contact</NavLink>
      </LinkStyle>
      <h1>Contact Us</h1>
      <p>
        We understand that every project is unique, and we approach each one
        with a personalized touch. Our mission is to exceed your expectations,
        and we achieve this by focusing on quality, precision, and attention to
        detail in every aspect of our work.
      </p>
    </ContentUp>
  );
};

const LastContent = () => {
  return (
    <GrayBackground>
      <h2>Why Work with us </h2>
      <p>
        At Sodmar, we take pride in our commitment to delivering exceptional,
        high-quality construction services that are perfectly tailored to meet
        your specific needs and vision. Our team of skilled professionals,
        engineers, architects, and craftsmen are dedicated to turning your
        dreams into reality, whether you're envisioning a new home, a commercial
        space, or a renovation project. We understand that every project is
        unique, and we approach each one with a personalized touch. Our mission
        is to exceed your expectations, and we achieve this by focusing on
        quality, precision, and attention to detail in every aspect of our work.
      </p>
    </GrayBackground>
  );
};

const Contact = () => {
  return (
    <>
      <ContactPageStyle>
        <MainContentUp />
      </ContactPageStyle>
      <ContactSection />
      <LastContent />
    </>
  );
};

export default Contact;
