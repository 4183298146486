import React, { useEffect, useState } from "react";
import {
  ButtonStyled,
  ButtonStyledSignOut,
  HeaderContainer,
  HeaderStyled,
  LogoStyled,
  NavStyled,
} from "./styled";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import MobileHeader from "./MobileHeader";
import styled from "styled-components";

export const Logo = () => {
  return (
    <div>
      <LogoStyled>
        <h1 className="font-[700] text-[32px]">SodMar</h1>
      </LogoStyled>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [screenWidth, setScreenWidth] = React.useState(window?.innerWidth || 0);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const handleSignOut = () => {
    dispatch(logOut());
    toast.success("Signout successful");
    navigate("/");
  };
  return (
    <>
      {screenWidth >= 1000 ? (
        <HeaderContainer
        // style={{ display: screenWidth <= 768 ? "none" : "block" }}
        >
          <div>
            <NavLink to={"/"}>
              <Logo />
            </NavLink>
          </div>
          <div>
            <ul>
              <NavStyled>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/homeprojectdisplay">Project</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
              </NavStyled>
            </ul>
          </div>
          <div>
            {user ? (
              <ButtonStyledSignOut onClick={handleSignOut}>
                Signout
              </ButtonStyledSignOut>
            ) : (
              <ButtonStyled onClick={() => navigate("/login")}>
                Login
              </ButtonStyled>
            )}
          </div>
        </HeaderContainer>
      ) : (
        <MobileHeader />
      )}
    </>
  );
};

export default Header;
