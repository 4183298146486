export const validateName = (name) => {
  return name !== "";
};
export const validateService = (service) => {
  return service !== "";
};
export const validateDate = (date) => {
  return date !== "";
};

export const validateEmail = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const validatePhone = (phone) => {
  const nigerianRegex = /^(?:\+234|0)[789]\d{9}$/;

  return nigerianRegex.test(phone);
};
