import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Spinner from "../../component/spinner/Spinner";
import { useGetAllBlogsQuery } from "../../redux/features/blog/blogApiSlice";
import { storeBlogs } from "../../redux/features/blog/blogSlice";
import BlogAuthor from "./BlogAuthor";
import { Link, NavLink } from "react-router-dom";
import PostBlog from "./PostBlog";

const Blog = () => {
  const dispatch = useDispatch();
  const url = window.location.href;

  const { data: blogs, isLoading: blogLoading } = useGetAllBlogsQuery();

  // Function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  };

  const shortenDescription = (description) => {
    return description.length > 150
      ? description.substring(0, 150) + "..."
      : description;
  };

  useEffect(() => {
    if (!blogLoading && blogs) {
      dispatch(storeBlogs(blogs.blogs));
    }
  });

  return (
    <>
      <BlogHomeWrapper>
        <CircleContainer
          style={{ display: url === "http://localhost:3000/" ? "none" : "" }}
        >
          <CircleStyle>
            <NavLink to="/post">+</NavLink>
          </CircleStyle>
        </CircleContainer>
        <h1
          style={{
            marginTop: url === "http://localhost:3000/" ? "" : "5rem",
          }}
          className="text-[32px] font-[700]"
        >
          Our Recent blogs
        </h1>
        <BlogHome>
          {blogLoading ? (
            <div
              style={{
                position: "relative",
              }}
            >
              <Spinner />
            </div>
          ) : !blogLoading && blogs && url === "http://localhost:3000/" ? (
            blogs?.blogs?.slice(0, 3).map((card, index) => {
              const { title, description, image, userId, createdAt, _id } =
                card;

              return (
                <>
                  <Link to={`/blogpost/${_id}`}>
                    <Card key={index}>
                      <InnerCard>
                        <div>
                          <img src={image} alt="image1" />
                          <div style={{ display: "flex", gap: "2rem" }}>
                            <div>
                              <BlogAuthor userId={userId} />
                            </div>
                            <div>{formatDate(createdAt)}</div>
                          </div>
                          <h1>{title}</h1>
                          <p
                            style={{ fontSize: "12px" }}
                            className="text-[14px]"
                          >
                            {shortenDescription(description)}
                          </p>
                        </div>
                      </InnerCard>
                    </Card>
                  </Link>
                </>
              );
            })
          ) : (
            blogs?.blogs?.map((card, index) => {
              const { title, description, image, userId, createdAt, _id } =
                card;

              return (
                <>
                  <Link to={`/blogpost/${_id}`}>
                    <Card key={index}>
                      <InnerCard>
                        <div>
                          <img src={image} alt="image1" />
                          <div style={{ display: "flex", gap: "2rem" }}>
                            <div>
                              <BlogAuthor userId={userId} />
                            </div>
                            <div>{formatDate(createdAt)}</div>
                          </div>
                          <h1>{title}</h1>
                          <p style={{ fontSize: "12px" }}>
                            {shortenDescription(description)}
                          </p>
                        </div>
                      </InnerCard>
                    </Card>
                  </Link>
                </>
              );
            })
          )}
        </BlogHome>
      </BlogHomeWrapper>
    </>
  );
};

export default Blog;

const BlogHomeWrapper = styled.div`
  padding-top: 12rem;
  color: #de8f5f;
  background-color: #faf8ed;
  text-align: center;
`;
const CircleContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
  padding-right: 12rem;
  padding-top: 5rem;
`;
const BlogHome = styled.div`
  padding: 3rem 12rem 5rem 12rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8rem;
    padding: 2rem 5rem 2rem 5rem;
  }
`;

const Card = styled.div`
  max-width: 133rem;
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  background-color: #faf8ed;
  img {
    max-width: 100%;
    width: 100%;
    height: 300px;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4rem;
  text-align: left;
  line-height: 20px;
  color: black;
`;
const CircleStyle = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background: var(--Primary1, #de8f5f);

  a {
    color: #fff;
  }

  flex-shrink: 0;

  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    a {
      color: #000;
    }
  }

  /* margin: auto; */
`;
