import React from "react";

import { FlexTop } from "./styled";
import ChooseUs from "../../component/choose us/Choose";
import HomeProjectDisplay from "./HomeProjectDisplay";
import { styled } from "styled-components";
import Text from "../../component/text/Text";
import Images from "../../component/images/Images";
import Blog from "../blog/Blog";
import Feedbacks from "../../component/feedbacks/Feedbacks";
import Services from "../../component/ourservices/Services";
import OurPartners from "../../component/partners/OurPartners";

const Home = () => {
  return (
    <>
      <div>
        <FlexTop className="flex flex-col md:flex-row gap-[3rem] justify-center items-center mx-[2rem] md:mx-[0]">
          <Text />
          <Images />
        </FlexTop>
        <Services />
        <HomeProjectDisplay />
        <div style={{ marginTop: "-50px" }}>
          <ChooseUs />
        </div>
        <OurPartners />
        <Feedbacks />

        <div>
          <Blog />
        </div>
      </div>
    </>
  );
};

export default Home;

export const Button = styled.button`
  padding: 15px;
  background-color: #de8f5f;
  color: #fff;
  font-weight: 400;
  border: 1px solid #de8f5f;
  border-radius: 8px;
  width: 200px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;
export const BookingButton = styled.button`
  padding: 15px;
  background-color: #de8f5f;
  color: #fff;
  font-weight: 400;
  border: 1px solid #de8f5f;
  border-radius: 8px;
  width: 200px;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BookingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  @media only screen and (max-width: 1000px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
    display: block;
    /* flex-direction: column; */
    /* justify-content: center; */
  }
`;
