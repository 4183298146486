import {
  BoxStyled,
  FlexSmallBox,
  MainBox,
  MainStyled,
  SmallBox,
} from "../../pages/home/styled";
import { initialData } from "../../service/data";

import buildImg2 from "../../assets/builder3.svg";
import { BookingButton, BookingWrapper } from "../../pages/home/Home";
import { useNavigate } from "react-router-dom";
const Box = () => {
  return (
    <>
      {initialData?.map((data, index) => {
        const { tittle, paragraph } = data;
        return (
          <div key={index} className="font-[Montserrat] md:mb-[1rem]">
            <h3 className="font-[Montserrat]">{tittle}</h3>
            <p className="font-[Montserrat] md:mb-[4rem]">{paragraph}</p>
          </div>
        );
      })}
    </>
  );
};
const ChooseUs = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center">
      <h2 className="text-left text-[32px] font-[700] font-[Montserrat] mt-[5rem] md:mt-[8rem]">
        Why Choose Us?
      </h2>
      <div className="flex flex-col md:flex-row gap-[3rem] max-w-[1200px] mx-auto mt-[3rem]">
        <div className="">
          <img src={buildImg2} className="w-full md:w-[600px]" alt="" />
        </div>
        <SmallBox className="w-full md:w-[600px]">
          <FlexSmallBox>
            <Box />
          </FlexSmallBox>
          <BookingWrapper>
            <BookingButton onClick={() => navigate("/booking")}>
              Book Now
            </BookingButton>
          </BookingWrapper>
        </SmallBox>
      </div>
    </div>
  );
};

export default ChooseUs;
