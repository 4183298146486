import styled from "styled-components";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logOut } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const MobileNavbar = () => {
  const { user } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(logOut());
    toast.success("Signout successful");
    navigate("/");
  };
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <ul
        style={{
          display: "flex",
          alignItems: "left",
          flexDirection: "column",
          marginTop: "-6rem",
          gap: "2rem",
          // padding: "2rem",
          listStyle: "none",
          color: "#000",
        }}
      >
        <li>
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="/admin/overview"
          >
            Overview
          </Link>
        </li>
        <li>
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="/admin/appointment"
          >
            Appointment
          </Link>
        </li>
        <li>
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="/admin/project"
          >
            Project
          </Link>
        </li>
        <li>
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="/admin/user"
          >
            User
          </Link>
        </li>
        <li>
          {user ? (
            <div onClick={handleSignOut} style={{ cursor: "pointer" }}>
              <Link style={{ textDecoration: "none", color: "#000" }} to="/">
                Sign Out
              </Link>
            </div>
          ) : (
            <Link style={{ textDecoration: "none", color: "#000" }} to="/login">
              Login
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default MobileNavbar;

const Text = styled.p``;
