"use client";
import React from "react";
import { useState } from "react";
import style from "styled-components";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import FormGroup from "@mui/material/FormGroup";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { responseErrorHandler } from "../../utils/errorHandler";

import { HiArchiveBox, HiMiniPencilSquare } from "react-icons/hi2";
import {
  useGetAllUsersQuery,
  useRemoveUserMutation,
  useUpdateUserMutation,
} from "../../redux/features/auth/authApiSlice";
import Notiflix from "notiflix";
import { CircularProgress } from "@mui/material";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "black" : "#F59E0B",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "Inter, sans-serif",
    color: "#54565A",
    fontWeight: "600px",
    RxLetterSpacing: "0.5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "",
    color: "#6B7280",
    opacity: 0.7,
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 450,
    fontSize: "17px",
    lineHeight: "20px",
    borderstyle: "none",
    // filter: "drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.02))",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#fff",
  borderColor: "#FFFFFF",
  borderWidth: "1px",
  paddingTop: "10px",
  paddingBottom: "30px",
  height: "55px",
}));

export default function User() {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [menus, setMenus] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { data: users, isLoading: userLoading } = useGetAllUsersQuery();
  const [removeUser] = useRemoveUserMutation();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const handleUserUpdate = async (e, id, credentials) => {
    e.preventDefault();
    try {
      const response = await updateUser({ id, ...credentials }).unwrap();
      if (response.status === 200) {
        toast.success(response.message);
        window.location.reload();
      }
    } catch (err) {
      const error = responseErrorHandler(err);
      toast.error(error);
    }
  };

  const handleDeleteUser = async (id) => {
    Notiflix.Confirm.show(
      "Delete user?",
      "Do you want to delete this user? this action is irreversible.",
      "Delete",
      "Cancel",
      async function okCb() {
        try {
          const response = await removeUser({ id }).unwrap();
          if (response.status === 201) {
            toast.success(response.message);
            window.location.reload();
          }
        } catch (err) {
          const error = responseErrorHandler(err);
          toast.error(error);
        }
      },
      function cancelCb() {
        toast.success("Delete cancelled");
      },
      {
        width: "600px",
        borderRadius: "8px",
        titleColor: "orangered ",
        okButtonBackground: "orangered ",
        cssAnimationStyle: "zoom",
        fontFamily: "Mulish",
      }
    );
  };

  return (
    // <PageContainer>
    <div style={{ width: "1200px", overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "60px",
          marginBottom: "40px",
        }}
      >
        <MenuList>Users </MenuList>
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography> Toggle mode</Typography>
          </Stack>
        </FormGroup>
      </div>
      <TableContainer component={Paper} style={{ border: "none" }}>
        <Table sx={{ minWidth: 1180 }} aria-label="customized table">
          <TableHead borderStyle="none">
            <TableRow>
              <StyledTableCell align="left"> ID </StyledTableCell>
              <StyledTableCell align="left">FIRSTNAME</StyledTableCell>
              <StyledTableCell align="left">LASTNAME</StyledTableCell>
              <StyledTableCell align="left">EMAIL</StyledTableCell>
              <StyledTableCell align="left">PHONE</StyledTableCell>
              <StyledTableCell align="left">ROLE</StyledTableCell>
              <StyledTableCell align="left">ACTIONS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "green" }}>
            {!userLoading &&
              users &&
              users?.users?.map((row, index) => {
                const { firstName, lastName, email, phoneNum, role } = row;
                return (
                  <>
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "#000",
                          fontWeight: 600,
                          fontSize: "12px",
                        }}
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                        }}
                      >
                        {firstName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                        }}
                      >
                        {lastName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                        }}
                      >
                        {email}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                        }}
                      >
                        {phoneNum}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                          fontWeight: "600",
                        }}
                      >
                        {role}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{
                          color: "black",
                          fontSize: "12px",
                          opacity: 0.65,
                          fontWeight: "600",
                        }}
                      >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <HiMiniPencilSquare
                            onClick={() => {
                              setMenus(row);
                              setOpen(true);
                            }}
                            style={{
                              color: "#de8f5f",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                          />
                          <HiArchiveBox
                            style={{
                              color: "#de8f5f",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteUser(row._id)}
                          />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users?.users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <div style={{ display: open === true ? "block" : "none" }}>
        <ModalDiv style={{ position: "absolute" }}>
          <Modal>
            <MText>Update User</MText>
            <MFormDiv>
              <MInputDiv>
                <InputField style={{ marginRight: "25px" }}>
                  <InputTest> Firstname </InputTest>
                  <Input defaultValue={menus.firstName} type="text" />
                </InputField>
                <InputField>
                  <InputTest>Lastname</InputTest>
                  <Input defaultValue={menus.lastName} type="text" />
                </InputField>
              </MInputDiv>
              <MInputDiv>
                <InputField style={{ marginRight: "25px" }}>
                  <InputTest>Phone Number</InputTest>
                  <Input defaultValue={menus.phoneNum} type="text" />
                </InputField>
                <InputField>
                  <InputTest>Email</InputTest>
                  <Input defaultValue={menus.email} type="text" />
                </InputField>
              </MInputDiv>
              <br />
              <InputField>
                <InputTest>Username</InputTest>
                <Input defaultValue={menus.username} type="text" />
              </InputField>
              <ButtonWrapper>
                {isLoading ? (
                  <CreateButton>
                    <CircularProgress style={{ color: "#fff" }} />
                  </CreateButton>
                ) : (
                  <CreateButton
                    onClick={(e) =>
                      handleUserUpdate(e, menus.id, {
                        firstName: menus.firstName,
                        lastName: menus.lastName,
                        phoneNum: menus.phoneNum,
                        email: menus.email,
                        username: menus.username,
                      })
                    }
                  >
                    Update
                  </CreateButton>
                )}
                <CreateButton onClick={handleClose}>Cancel</CreateButton>
              </ButtonWrapper>
            </MFormDiv>
          </Modal>
        </ModalDiv>
      </div>
    </div>
  );
}

const ButtonWrapper = style.div`
display: flex;
gap: 1rem;
`;

export const MenuList = style.p`

color: #000;
font-family: 'Inter', sans-serif;
font-size: 28px;
font-style: normal;
font-weight: 500;
line-height: 18px;
letter-spacing: -0.1px;
`;

const ModalDiv = style.div`
top: 0%;
left: 0%;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background: rgba(101, 95, 95, 0.50);
z-index: 100;

`;

const Modal = style.div`
onClick={(e) => e.stopPropagation()};
padding: 20px;
position: absolute;
top: 14%;
width: 800px;
height: 420px;
border-radius: 5px;
background: var(--neutral-50, #FAFAFA);
`;

const MFormDiv = style.div`
width: 100%;
padding-left: 20px;
padding-right: 20px;


  
`;

const MInputDiv = style.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-top: 10px;
  

`;
const InputField = style.div`
width: 100%;
`;

const Input = style.input`
width: 100%;
border-radius: 6px;
border: 1px solid var(--neutral-200, #E5E5E5);
background: var(--generic-white, #FFF);
padding: 8px 12px;
outline: none;
`;

const MText = style.p`
padding: 20px;
box-shadow: 0px 1px 0px 0px #e4e8ee;
color: var(--neutral-900, #171717);
font-family: "Inter" sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px;

  

`;

const InputTest = style.p`
color: var(--neutral-900, #171717);
font-family: "Inter" sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
margin-bottom: 6px;

  
`;

const CreateButton = style.button`
  border: 1px solid #f59e0b;
  border-radius: 4px;
  background: #f59e0b;
  padding: 10px 16px;
  color: #fff;
  text-align: justify;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
margin-top: 40px;
`;
