import React, { useState } from "react";
import styled from "styled-components";
import { useCreateBlogMutation } from "../../redux/features/blog/blogApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { uploadBlogImg } from "../services/addProjectService";
import { CircularProgress } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";

const apiKey = process.env.REACT_APP_API_KEY;
const PostBlog = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [createBlog, { isLoading }] = useCreateBlogMutation();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handlePost = async (e) => {
    e.preventDefault();

    if (!image) {
      toast.error("Please upload an image.");
      return;
    }
    try {
      const data = {
        description,
        title,
        userId: user._id,
      };

      const response = await createBlog(data).unwrap();

      const uploadResponse = await uploadBlogImg(image, response.blog._id);

      if (response && uploadResponse && !isLoading) {
        toast.success("Your blog has been uploaded successfully");
        navigate("/blog");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error uploading the file or sending data");
    }
  };
  return (
    <div
      style={{
        marginTop: "15rem",
        marginBottom: "5rem",
        background: "",
      }}
    >
      <HeadingFlex>
        <h1
          style={{
            marginTop: "1rem",
          }}
        >
          Create Blog Post
        </h1>
      </HeadingFlex>
      <FormWrapper>
        <Form>
          <InputDiv>
            <label htmlFor="title">Title</label> <br />
            <InputField>
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Enter post title"
                autoComplete="off"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputField>
          </InputDiv>
          <InputDiv>
            <label htmlFor="password">Post Picture</label> <br />
            <InputField>
              <input
                id="image"
                type="file"
                name="image"
                accept="image/*"
                onChange={handleFileChange}
              />
            </InputField>
          </InputDiv>
          <br />
          <br />
          <InputDiv>
            <label htmlFor="description">Post</label>
            <TextField>
              <Editor
                apiKey={apiKey}
                value={description}
                onEditorChange={(newValue, content) =>
                  setDescription(content.getContent({ format: "text" }))
                }
                init={{
                  height: 500,
                  menubar: false,
                  plugins:
                    "ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                  toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                  tinycomments_mode: "embedded",
                  tinycomments_author: "Author name",
                  mergetags_list: [
                    { value: "First.Name", title: "First Name" },
                    { value: "Email", title: "Email" },
                  ],
                  ai_request: (request, respondWith) =>
                    respondWith.string(() =>
                      Promise.reject("See docs to implement AI Assistant")
                    ),
                }}
                // initialValue="Welcome to TinyMCE!"
              />
              {/* //   <textarea */}
              {/* //     id="description"
            //     name="description"
            //     cols={30}
            //     type="text"
            //     placeholder="Enter post"
            //     autoComplete="off"
            //     value={description}
            //     onChange={(e) => setDescription(e.target.value)} */}
              {/* //   ></textarea> */}
            </TextField>
          </InputDiv>

          {isLoading ? (
            <SubmitBtn>
              <CircularProgress style={{ color: "#fff" }} />
            </SubmitBtn>
          ) : (
            <SubmitBtn
              name="submit"
              type="submit"
              onClick={(e) => handlePost(e)}
            >
              Post
            </SubmitBtn>
          )}
        </Form>
      </FormWrapper>
    </div>
  );
};

export default PostBlog;

const HeadingFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubmitBtn = styled.button`
  border: none;
  margin-top: 50px;
  display: flex;
  cursor: pointer;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #de8f5f;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    opacity: 0.25;
    background: #de8f5f;
    cursor: not-allowed;
  }
`;

const Form = styled.form`
  width: 70%;
`;

const InputDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 100%;
    height: 1.6rem;
    margin-top: 5px;
    outline: none;
    border: none;
    color: rgba(102, 102, 102, 0.6);
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  textarea {
    width: 100%;
    height: 1.6rem;
    margin-top: 5px;
    outline: none;
    border: none;
    color: rgba(102, 102, 102, 0.6);
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  label {
    color: #666;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const InputField = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 15px;
`;
const TextField = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 15px;
`;
