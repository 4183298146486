import styled from "@emotion/styled";
import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useGetAllProjectsQuery } from "../../redux/features/project/projectApiSlice";
import { useDispatch } from "react-redux";
import { storeProjects } from "../../redux/features/project/projectSlice";
import Spinner from "../../component/spinner/Spinner";

const HomeProjectDisplay = () => {
  const { data: projects, isLoading: projectLoading } =
    useGetAllProjectsQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!projectLoading && projects) {
      dispatch(storeProjects(projects.projects));
    }
  });

  return (
    <ProjectHomeWrapper className="flex flex-col justify-center items-center">
      <h1
        style={{
          marginTop: "15rem",
        }}
        className="text-[32px] font-[700]"
      >
        Our Recent Projects
      </h1>
      <ProjectHome>
        {projectLoading ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <Spinner />
          </div>
        ) : (
          !projectLoading &&
          projects &&
          projects?.projects?.map((card, index) => {
            const { name, location, description, image } = card;
            return (
              <>
                <Card key={index}>
                  <img src={image} alt="image1" />
                  <span>
                    <h4>{name}</h4>
                    <Button>
                      <TransitionsModal
                        description={description}
                        location={location}
                        name={name}
                      />
                    </Button>
                  </span>
                </Card>
              </>
            );
          })
        )}
      </ProjectHome>
    </ProjectHomeWrapper>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: 400,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default HomeProjectDisplay;

export const TransitionsModal = ({ description, location, name }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>+</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {name}
            </Typography>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {location}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {description}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
const ProjectHomeWrapper = styled.div`
  font-family: Montserrat;

  padding-top: 2rem;
  padding-bottom: 5rem;
  color: #de8f5f;

  /* background-color: #e4e4d0; */
  background-color: #faf8ed;
  text-align: center;
  @media only screen and (max-width: 768px) {
    h1 {
      text-align: left;
      padding: 4rem;
    }
  }
`;
const ProjectHome = styled.div`
  padding-left: 12rem;
  padding-right: 8rem;
  display: grid;
  padding-top: 2rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem;

  @media only screen and (max-width: 1000px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 4rem;
    gap: 3rem;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 4rem;
    gap: 3rem;
  }
`;

const Card = styled.div`
  width: 400px;
  height: 500px;
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  background-color: #f0f0f0;
  img {
    width: 400px;
    height: 440px;
    border-radius: 20px 20px 0 0;
  }
  button {
    background-color: #101828;
    border-radius: 0px 10px 10px 0;
    font-size: 20px;
    color: #fff;
    /* width: 100px; */
  }
  span {
    display: inline-flex;
    padding: 0%.5rem;
  }
  h4 {
    width: 310px;
    height: 59px;
    border-radius: 10px 0 0 10px;
    text-align: center;
    align-items: center;
    color: #fff;
    padding: 1rem;
    font-size: 18px;
    /* margin-right: 20px; */
    background-color: #de8f5f;
    display: inline-flex;
  }
  @media only screen and (max-width: 768px) {
    width: 220px;
    height: 280px;
    gap: 5rem;
    margin-top: 2rem;

    img {
      width: 200px;
      height: 220px;
      border-radius: 10px 10px 10px 10px;
    }
    h4 {
      width: 210px;
      height: 60px;
      border-radius: 10px 10px 10px 10px;
    }
    button {
      display: none;
    }
  }
`;
