import React from "react";
import styled from "styled-components";
import { Logo } from "../header/Header";
import { Link } from "react-router-dom";
import { AiFillTwitterCircle, AiOutlineMail } from "react-icons/ai";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <FooterStyled className="flex flex-col justify-center items-start  md:flex-row text-white font-[Montserrat]">
      <div>
        <Logo />
        <LinkStyled>
          <Link to="/">
            <AiFillTwitterCircle />
          </Link>
          <Link to="/">
            <FaLinkedin style={{ color: "white" }} />
          </Link>
          <Link to="/">
            {" "}
            <FaFacebook style={{ color: "white" }} />
          </Link>
          <Link to="/">
            {" "}
            <FaInstagram style={{ color: "white" }} />
          </Link>
        </LinkStyled>
        <div className="font-[Montserrat]">
          <p className="font-[Montserrat]">
            All right Reserved &copy;Sodmar Construction Limited {year}{" "}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-[5rem] md:flex-row justify-start items-start font-[Montserrat]">
        <div>
          <h4>About us</h4>
          <p>
            {" "}
            Welcome to Sodmar Construction Company, where we provide
            high-quality building solutions that are tailored to meet your
            specific needs.
          </p>
        </div>
        <div>
          <h4>Our service</h4>
          <p>Building Approval</p>
          <p>Mechanical Design</p>
          <p>Structural Design</p>
          <p>Project Supervision</p>
          <p>Land Surveying</p>
          <p>Architectural Drawing</p>
          <p>Real Estate Agency</p>
          <p>Facility Management</p>
        </div>
      </div>
      <ContactStyled>
        <h4>Contact Us</h4>
        <div className="flex flex-row gap-[.5rem]">
          <AiOutlineMail />
          <span> sodmarconcept@gmail.com</span>
        </div>
        <br />
        <div className="flex flex-row justify-center items-center gap-[.5rem]">
          <HiOutlineLocationMarker />{" "}
          <span>
            10, Moloney Bus-Stop, Express Road, Federal Polytechnic Ilaro, Ogun
            State
          </span>
        </div>
      </ContactStyled>
    </FooterStyled>
  );
};

const FooterStyled = styled.div`
  height: 366px;
  flex-shrink: 0;
  background-color: #292d32;

  gap: 10rem;
  /* grid-template-columns: repeat(4, 1fr); */

  padding: 12rem;

  padding-top: 8rem;
  padding-bottom: 0;

  h4 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p {
    color: #e8e7e7;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 5rem;
    height: 70rem;
    padding: 4rem;
  }
`;

const LinkStyled = styled.a`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* width: 30px;
  height: 30px; */
  padding-top: 2rem;
  flex-shrink: 0;
  a {
    font-size: 20px;
    color: #e8e7e7;
  }
`;

const ContactStyled = styled.div`
  h4 {
    padding-bottom: 2rem;
  }
  p {
    color: #e8e7e7;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  a {
    font-size: 16px;
    color: #e8e7e7;
    text-decoration: none;
    line-height: 30px;
    font-family: Inter;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -30px;
  }
`;

export default Footer;
