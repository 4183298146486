import React, { useEffect, useState } from "react";
import {
  ButtonStyled,
  ButtonStyledSignOut,
  HeaderContainer,
  HeaderStyled,
  LogoStyled,
  NavStyled,
} from "../styled";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import styled from "styled-components";
import MobileNavbar from "./MobileNavbar";
import { RxContainer, RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { Logo } from "../Header";

const Header = () => {
  const [nav, setNav] = useState(false);

  const [screenWidth, setScreenWidth] = React.useState(window?.innerWidth || 0);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const handleToggle = () => {
    setNav(!nav);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const handleSignOut = () => {
    dispatch(logOut());
    toast.success("Signout successful");
    navigate("/");
  };

  return (
    <Box>
      <Container>
        <NavFlex>
          <FlexRow>
            <IconControlBox onClick={handleToggle}>
              {!nav ? (
                <RxHamburgerMenu style={{ fontSize: "30px" }} />
              ) : (
                <AiOutlineClose style={{ fontSize: "30px" }} />
              )}
            </IconControlBox>
            <NavLogoContainer>
              <NavLink to={"/"}>
                <Logo />
              </NavLink>
            </NavLogoContainer>

            <NavArea className="">
              <NavList>
                <ListItem>
                  <StyledLink
                    to="/"
                    className={"text-[18px] font-[Montserrat]"}
                  >
                    Home
                  </StyledLink>
                </ListItem>
                <ListItem>
                  <StyledLink
                    to="/homeprojectdisplay"
                    className={"text-[18px] font-[Montserrat]"}
                  >
                    Project
                  </StyledLink>
                </ListItem>
                <ListItem>
                  <StyledLink
                    to="/contact"
                    className={"text-[18px] font-[Montserrat]"}
                  >
                    Contact
                  </StyledLink>
                </ListItem>
                <ListItem>
                  <StyledLink
                    to="/blog"
                    className={"text-[18px] font-[Montserrat]"}
                  >
                    Blog
                  </StyledLink>
                </ListItem>
              </NavList>
            </NavArea>
            <Wrap>
              {screenWidth <= 1024 ? null : user ? (
                <ButtonStyledSignOut onClick={handleSignOut}>
                  Signout
                </ButtonStyledSignOut>
              ) : (
                <button
                  className="btn bg-[#15265E] text-white rounded-full text-[18px] w-full md:w-[150px] py-3 h-[50px] hover:bg-[#de8f5f] px-6 flex justify-center items-center"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              )}
            </Wrap>
          </FlexRow>
          {!nav ? (
            <div style={{ display: "none" }} />
          ) : screenWidth <= 1024 ? (
            <MobileNavbar />
          ) : null}
        </NavFlex>
      </Container>
    </Box>
  );
};
export default Header;

const StyledLink = styled(NavLink)`
  color: #000;

  &:hover {
    border-bottom: 2px solid #de8f5f;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 50px;
  @media (min-width: 425px) {
    gap: 100px;
  }
  @media (min-width: 1440px) {
    gap: 222px;
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;
const NavFlex = styled.div`
  margin-top: 40px;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px 30px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(
    129deg,
    rgba(255, 255, 255, 0.65) 0%,
    rgba(255, 255, 255, 0.25) 100%
  );
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);
  @media (min-width: 425px) {
    gap: 50px;
    padding: 20px 30px;
    width: 100%;
  }
  @media (min-width: 1440px) {
    gap: 222px;
    padding: 20px 30px;
    width: 1259.759px;
  }
`;
const Box = styled.div`
  background-color: #fffcf7;
`;

const NavLogoContainer = styled.div``;
// const Image = styled.img`
//   width: 82px;
//   @media (min-width: 1024px) {
//     width: 82px;
//   }
// `;

const NavArea = styled.div`
  display: none;
  flex-direction: row;
  gap: 2rem;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const NavList = styled.ul`
  list-style-type: none;
  font-family: Mulish;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  font-size: 14px;
  color: #000;
`;
const ListItem = styled.li`
  display: inline;
`;

// const NavLink = styled(Link)`
//   text-decoration: none;
//   padding: 10px;
//   color: #000;
//   cursor: pointer;
//   margin-top: 20px;

//   &:hover {
//     border-bottom: solid #f97316 3px;
//     width: 2px;
//   }
// `;

const IconControlBox = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
