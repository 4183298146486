import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ButtonStyled,
  ButtonStyledSignOut,
} from "../../component/header/styled";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import {
  HiOutlineCalendarDays,
  HiOutlineHome,
  HiOutlineHomeModern,
  HiOutlineUsers,
} from "react-icons/hi2";
import MobileNavbar from "./AdminMobileNavbar";

const AdminNav = () => {
  const [nav, setNav] = useState(false);
  const [screenWidth, setScreenWidth] = React.useState(window?.innerWidth || 0);

  const handleToggle = () => {
    setNav(!nav);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(logOut());
    toast.success("Signout successful");
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);
  return (
    <AdminNavStyle>
      <IconControlBox onClick={handleToggle}>
        {!nav ? (
          <RxHamburgerMenu style={{ fontSize: "30px" }} />
        ) : (
          <AiOutlineClose style={{ fontSize: "30px" }} />
        )}
      </IconControlBox>
      {screenWidth <= 1024 ? null : (
        <>
          <LogoNav>
            <NavLink to={"/"}>
              <h1>SodMar</h1>
            </NavLink>
          </LogoNav>

          <Navbars>
            <nav>
              <ul>
                <li>
                  <StyledNavLink to="/admin/overview">
                    <HiOutlineHome />
                    <span>Overview</span>
                  </StyledNavLink>
                </li>
                <li>
                  <StyledNavLink to="/admin/project">
                    <HiOutlineCalendarDays />
                    <span>Projects</span>
                  </StyledNavLink>
                </li>
                <li>
                  <StyledNavLink to="/admin/appointment">
                    <HiOutlineHomeModern />
                    <span>Appointments</span>
                  </StyledNavLink>
                </li>
                <li>
                  <StyledNavLink to="/admin/user">
                    <HiOutlineUsers />
                    <span>Users</span>
                  </StyledNavLink>
                </li>
              </ul>
            </nav>

            <ButtonDown>
              {user ? (
                <ButtonStyledSignOut onClick={handleSignOut}>
                  Sign Out
                </ButtonStyledSignOut>
              ) : (
                <ButtonStyled onClick={() => navigate("/login")}>
                  Login
                </ButtonStyled>
              )}
            </ButtonDown>
          </Navbars>
        </>
      )}

      {!nav ? (
        <div style={{ display: "none" }} />
      ) : screenWidth <= 1024 ? (
        <MobileNavbar />
      ) : null}
    </AdminNavStyle>
  );
};

const IconControlBox = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const StyledNavLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: #ccc;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.2rem 2.4rem;
    transition: all 0.3s;
  }

  /* This works because react-router places the active class on the active NavLink */
  &:hover,
  &:active,
  &.active:link,
  &.active:visited {
    color: #fff;
    background-color: #de8f5f;
    border-radius: 8px;
  }

  & svg {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-grey-400);
    transition: all 0.3s;
  }

  &:hover svg,
  &:active svg,
  &.active:link svg,
  &.active:visited svg {
    color: var(--color-brand-600);
  }
  font-family: Inter;
`;

const AdminNavStyle = styled.div`
  display: inline-flex;
  width: 18%;
  height: 100vh;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  gap: 110px;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    max-width: 300px;
    width: 100%;
  }
`;

const LogoNav = styled.h1`
  a {
    color: #de8f5f;
    font-family: Inter;
  }
`;
const Navbars = styled.div`
  ul {
    display: flex;
    height: 270px;
    flex-direction: column;
    /* align-items: center; */
    gap: 27px;
    flex-shrink: 0;
  }
  li {
    list-style: none;
    font-size: 18px;
    font-weight: 400;
  }
  a {
    color: #404040;
  }
`;
const ButtonDown = styled.div`
  margin-top: 8rem;
`;

export default AdminNav;
