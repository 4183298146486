import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from "./features/auth/authSlice";
import serviceReducer from "./features/services/serviceSlice";
import projectReducer from "./features/project/projectSlice";
import appointmentReducer from "./features/appointments/appointmentSlice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    service: serviceReducer,
    project: projectReducer,
    appointment: appointmentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

export default store;
