import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import { loginErrorHandler } from "../../utils/errorHandler";
import { useCreateServiceMutation } from "../../redux/features/services/serviceApiSlice";
import { LogoStyled } from "../../component/header/styled";

const CreateServices = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [createService, { isLoading }] = useCreateServiceMutation();

  const createServiceHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await createService({ name }).unwrap();

      if (response) {
        toast.success("service created successfully");
        setName("");
        navigate("/#createservice");
      }
    } catch (err) {
      let errorMessage = loginErrorHandler(err);
      toast.error(errorMessage);
    }
  };
  return (
    <div>
      <MainDiv>
        <FormContainer>
          <FormBox>
            <div>
              <LogoStyled>
                <h1>SodMar</h1>
              </LogoStyled>
            </div>
            <Text> Welcome Back </Text>
            <SignUpText>Create Company Services</SignUpText>
            <Form>
              <InputDiv>
                <label htmlFor="name">Service Name</label> <br />
                <br />
                <InputField>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter service name"
                    autoComplete="off"
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputField>
              </InputDiv>

              {isLoading ? (
                <SubmitBtn>
                  <CircularProgress style={{ color: "#fff" }} />
                </SubmitBtn>
              ) : (
                <SubmitBtn
                  name="submit"
                  type="submit"
                  onClick={(e) => createServiceHandler(e)}
                >
                  Create
                </SubmitBtn>
              )}
            </Form>
          </FormBox>
        </FormContainer>
      </MainDiv>
    </div>
  );
};

export default CreateServices;

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  background-image: url("/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    background: none;
  }
`;

const FormBox = styled.div`
  width: 486px;
  /* height: 580px; */
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  gap: 0;
  @media (max-width: 1000px) {
    /* width: 100vw; */
    /* height: 100%; */
  }
  img {
    margin-bottom: 30px;
  }
`;

const SubmitBtn = styled.button`
  border: none;
  margin-top: 50px;
  display: flex;
  cursor: pointer;
  padding: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #de8f5f;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    opacity: 0.25;
    background: #de8f5f;
    cursor: not-allowed;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Text = styled.h4`
  color: #333;
  text-align: center;
  font-size: 32px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 7px;
  margin: 0 auto;
`;

const SignUpText = styled.h6`
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
`;

const InputDiv = styled.div`
  width: 100%;
  margin-top: 30px;
  input {
    width: 100%;
    height: 1.6rem;
    margin-top: 5px;
    outline: none;
    border: none;
    color: rgba(102, 102, 102, 0.6);
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  label {
    color: #666;
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const InputField = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  padding: 15px;
`;
