import React from "react";
import styled from "styled-components";

import { Route, Routes } from "react-router-dom";
import AdminNav from "./AdminNav";
import OverView from "./OverView";
import Appointment from "./Appointment";
import Project from "../projects/Project";
import User from "./User";
const Admin = () => {
  return (
    <AdminPage>
      <AdminNav />
      <AdminTable>
        <Routes>
          <Route path="overview" element={<OverView />} />
          <Route path="project" element={<Project />} />
          <Route path="appointment" element={<Appointment />} />
          <Route path="user" element={<User />} />
        </Routes>
      </AdminTable>
    </AdminPage>
  );
};
export default Admin;

const AdminPage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const AdminTable = styled.h1`
  padding: 3rem;
  min-height: 80vh;
  width: 75%;
`;
