import styled from "styled-components";

export const FlexTop = styled.div`
  padding-top: 20rem;
  /* background-color: #e4e4d0; */
  background-color: #faf8ed;
  // @media only screen and (max-width: 768px) {
  //   display: flex;
  //   align-items: st;
  //   padding-left: 4rem;
  //   flex-direction: column;
  //   gap: 10px;

  //   padding-top: 13rem;
  // }
`;

export const BuildingStyled = styled.div`
  color: #de8f5f;

  h2 {
    color: #000;
  }
  p {
    color: #5f5f5f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-left: 3px solid #de8f5f;
    padding-left: 4px;
  }
`;

export const ImageStyled = styled.image`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    /* margin-left: -6rem; */
  }
`;
// choose us
export const MainStyled = styled.div`
  margin-top: 2rem;
  /* margin-bottom: 15rem; */
  height: auto;
  flex-shrink: 0;

  /* background-color: #e4e4d0; */
  background-color: #faf8ed;
  h2 {
    display: block;
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 12rem;
    padding-top: 5rem;
  }
  @media only screen and (max-width: 1000px) {
    height: 100rem;
    h2 {
      /* text-align: center; */
      padding: 4rem;
    }
  }
`;

export const MainBox = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20rem;

  padding: 12rem;
  padding-top: 8rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    align-items: center;

    img {
      width: 190px;
      height: 190px;
      margin-left: 30px;
      margin-top: -30px;
      /* align-items: center; */
    }
  }
`;

export const SmallBox = styled.div`
  gap: 3rem;

  p {
    color: #5f5f5f;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h3 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media only screen and (max-width: 1000px) {
    p {
      width: 230px;
      text-align: justify;

      font-size: 16px;

      font-weight: 200;
      line-height: normal;
    }
    h3 {
      width: 240px;
    }
  }
`;

export const FlexSmallBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
  justify-items: center;
  gap: 3rem;
  h2 {
    text-align: left;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);

    gap: 3rem;
  }
  /* @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);

    gap: 3rem;
  } */
`;
export const BoxStyled = styled.div`
  width: 315px;
  height: 145px;
  @media only screen and (max-width: 1000px) {
    width: 200px;
    height: 80px;
    margin-top: 2rem;
  }
`;
