import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Footer from "./component/footer/Footer";
import CreateServices from "./pages/services/createServices";
import NotFound from "./pages/notFound/NotFound";
import { useSelector } from "react-redux";
import Admin from "./pages/admin/Admin";
import Blog from "./pages/blog/Blog";
import Appointment from "./component/appointment/Appointment";

import HomeProjectDisplay from "./pages/home/HomeProjectDisplay";
import CreateAdmin from "./pages/admin/CreateAdmin";
import Header from "./component/header/Header/Header";
import BlogSinglePost from "./pages/blog/BlogSinglePost";
import PostBlog from "./pages/blog/PostBlog";

function App() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const auth = (element) => {
    if (user && user.role === "admin") {
      return element;
    } else {
      return <Navigate to="/" />;
    }
  };

  // Define an array of routes where you want to hide the header and footer.
  const routesWithoutHeaderFooter = [
    "/login",
    "/register",
    "/admin",
    "/admin/user",
    "/admin/overview",
    "/admin/appointment",
    "/admin/project",
    "/createservice",
    // "/booking",
  ];

  const shouldDisplayHeaderFooter = !routesWithoutHeaderFooter.includes(
    location.pathname
  );
  return (
    <>
      {shouldDisplayHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route index path="/register" element={<SignUp />} />
        <Route index path="/createadmin" element={auth(<CreateAdmin />)} />
        <Route path="/createservice" element={auth(<CreateServices />)} />
        <Route path="/post" element={auth(<PostBlog />)} />
        <Route path="/*" element={<NotFound />} />
        {/* <Route index path="/service" element={<Service />} /> */}
        {/* <Route index path="/clientproject" element={<LandingPageProject />} /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogpost/:id" element={<BlogSinglePost />} />
        <Route path="/booking" element={<Appointment />} />
        <Route path="/homeprojectdisplay" element={<HomeProjectDisplay />} />
        {/* <AdminRoute> */}
        <Route path="/admin/*" element={auth(<Admin />)} />
      </Routes>
      {shouldDisplayHeaderFooter && <Footer />}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
