import Marquee from "react-fast-marquee";

export const SponsorsData = [
  {
    logo: "/esv.png",
    width: 120,
  },
  {
    logo: "/ifma.png",
    width: 120,
  },
  {
    logo: "/niesv2.png",
    width: 60,
  },
  {
    logo: "/esv.png",
    width: 120,
  },
  {
    logo: "/ifma.png",
    width: 120,
  },
  {
    logo: "/niesv2.png",
    width: 60,
  },
  {
    logo: "/esv.png",
    width: 120,
  },
  {
    logo: "/ifma.png",
    width: 120,
  },
  {
    logo: "/niesv2.png",
    width: 60,
  },
];

const OurPartners = () => {
  return (
    <div className="flex  flex-col gap-8 justify-center items-center md:mt-[5rem]">
      <h1 className=" text-2xl md:text-4xl  text-[#15265E] text-center font-bold font-[Montserrat]">
        Our past partners
      </h1>
      <Marquee>
        <div className="flex  flex-row gap-[1.5rem]  lg:gap-[3rem] justify-center items-center">
          {SponsorsData.map((i, index) => (
            <div key={index}>
              <img alt="" width={i.width} height={10} src={i.logo} />
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};
export default OurPartners;
