import axios from "axios";
import Cookies from "js-cookie";

const baseEndPoint =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_PROD
    : process.env.REACT_APP_BACKEND_DEV;

// Get the token from your storage (e.g., localStorage or cookies)
const token = Cookies.get("accessToken");

export const uploadImg = async (file, projectId) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("projectId", projectId);
  const uploadResponse = await axios.post(
    `${baseEndPoint}/project/upload`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the headers
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return uploadResponse.data;
};
export const uploadBlogImg = async (file, blogId) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("blogId", blogId);
  const uploadResponse = await axios.post(
    `${baseEndPoint}/blog/uploadImage`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the headers
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return uploadResponse.data;
};
