import React from "react";
import { useParams } from "react-router-dom";
import { useGetSingleBlogQuery } from "../../redux/features/blog/blogApiSlice";
import BlogAuthor from "./BlogAuthor";
import styled from "styled-components";

const BlogSinglePost = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetSingleBlogQuery(id);
  console.log(data);
  // Function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()}`;
    return formattedDate;
  };

  return (
    <div
      style={{
        marginTop: "15rem",
      }}
      className="max-w-[800px] mx-auto flex flex-col items-start"
    >
      <SingleBlogBody>
        <SingleImage>
          <img src={!isLoading && data && data?.blog?.image} alt="" />
        </SingleImage>

        <h1>{!isLoading && data?.blog?.title}</h1>
        <p>{!isLoading && data?.blog?.description}</p>
      </SingleBlogBody>
      <Wrapper>
        <div>
          <BlogAuthor userId={!isLoading && data?.blog?.userId} />
        </div>
        <div>{!isLoading && formatDate(data?.blog?.createdAt)}</div>
      </Wrapper>
    </div>
  );
};

export default BlogSinglePost;

const Wrapper = styled.div`
  display: flex;
  padding: 12rem;
  padding-top: 0;
  padding-bottom: 2rem;
  gap: 1rem;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const SingleImage = styled.div`
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding-bottom: 2rem;
    @media (max-width: 450px) {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const SingleBlogBody = styled.div`
  padding: 12rem;
  padding-top: 5rem;
  padding-bottom: 2rem;
  gap: 5rem;

  @media (max-width: 450px) {
    h1 {
      width: 200px;
    }
    p {
      width: 210px;
    }
  }
`;
